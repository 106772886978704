<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-account-statements"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      @hidden="resetUrl"
    >
      <b-container fluid class="pl-4 pt-4 pr-4">
        <b-row class="panel" v-if="!loading">
          <b-col v-if="!has_antecipation">
            <b-overlay :show="loading">
              <!-- Amount -->
              <div class="voltar-login d-md-none d-block">
                <router-link to="/extrato">
                  <img src="@/assets/img/icons/arrow-page.svg" />
                  {{ $t("views.seller.password_recovery.text_1470") }}
                </router-link>
              </div>
              <b-row class="mt-md-5" >
                <b-col  cols="8" class="sumary">
                  <p
                    data-anima="bottom"
                    class="dados-campo amount-available"
                    :class="{  
                    'entry': statement.type_transaction === 'INCOME',
                    'exit': statement.type_transaction === 'OUT_FLOW',
                    'heaven': isHeaven }"
                  >
                    {{ statement.balance > 0 ? "+" : "-" }}
                    {{ isHeaven ? '$' : 'R$' }}
                    {{ statement.balance > 0 ? formatMoney(statement.balance) : formatMoney(statement.balance * -1) }}
                  </p>
                </b-col>
                <b-col cols="4" class="badge-transaction"
                :class="{ 
                  'entry': statement.type_transaction === 'INCOME',
                  'exit': statement.type_transaction === 'OUT_FLOW'                  
                  }"
                  >                      
                <b-badge class="transactions__status" variant="success" >
                 <span class="transactions__text" >
                  {{$t(statement.type_translate)}}
                   </span>         
                  </b-badge> 
       
                </b-col>
              </b-row>

              <b-row class="mt-4 item-dados">
                <b-col class="sumary">
                  <b-row>
                    <b-col cols="12" class="mb-4">
                      <p :class="{
                        'entry': statement.type_transaction === 'INCOME',
                        'exit': statement.type_transaction === 'OUT_FLOW',
                      }"
                      class="header-text"
                      >
                      {{$t('seller.statements.modals.antecipation.text_796')}}
                      </p>
                    </b-col>
           
                    <b-col cols="6" class="mb-4">
                      <p class="label-campo">
                        <span class="title-item">{{ $t('seller.contracts.right_preview.text_1789') }}</span>
                        <span class="subtitle-item">#{{ statement.id }}</span>
                      </p>
                    </b-col>

                    <b-col cols="6" class="mb-4">
                      <p class="label-campo" v-if="statement.available_date">
                        <span class="title-item">
                          {{ $t('seller.statements.modals.withdraw.text_863') }}
                        </span>
                        <span class="subtitle-item">
                          {{ statement.available_date | datetime }}
                        </span>
                      </p>
                      <p class="label-campo" v-else-if="statement.created_at">
                        <span class="title-item">
                          {{$t('seller.products.modal_response.text_573') + ' ' + $t('seller.products.modal_response.text_583') }}
                        </span>
                        <span class="subtitle-item">
                          {{ statement.created_at | datetime }}
                        </span>
                      </p>
                    </b-col>    
                    
                    <b-col v-if="statement.sale_id" cols="6" class="mb-4"> 
                        <span class="title-item">
                          {{ $t('seller.statements.modals.withdraw.text_864') }}
                        </span>
                        <span class="subtitle-item">#{{ statement.sale_id }}</span>
                    </b-col>

                    <b-col v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'" cols="6" class="mt-2">                  
                        <span class="title-item">{{ $t('seller.statements.modals.withdraw.text_863') }}</span>
                        <span class="subtitle-item">{{ statement.available_date | date }}</span>
                    </b-col>
                    
            
                    <b-col v-if="statement.type == 'WITHDRAW' || statement.type == 'WITHDRAW_FEE'" cols="6">                    
                        <span class="title-item">
                          {{ $t('seller.statements.modals.withdraw.text_866') }}
                        </span>
                        <span class="subtitle-item">{{ statement.withdraw_id }}</span> 
                    </b-col>

                    <b-col v-if="statement.withdrawals" cols="6">                    
                        <span class="title-item">
                          {{ $t('views.seller.statements.withdraw_done') }}
                        </span>
                        <span class="subtitle-item">
                          {{ statement.withdrawals.created_at | datetime }}
                        </span> 
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Product -->
              <b-row
                class="item-dados mt-2 mb-4"
                v-if="statement.product"
              >
               
                  <b-col cols="12">
                    <h5 class="titulo-item mt-2 mb-4" :class="{
                      'heaven': isHeaven,
                      'entry': statement.type_transaction === 'INCOME',
                      'exit': statement.type_transaction === 'OUT_FLOW',
                        }">{{ $t('seller.contracts.right_preview.text_1794') }}</h5>
                  </b-col>

                  <div class="d-md-flex d-block text-left text-md-center">
                    
                    <b-col cols="12" md="4">
                      <img
                      class="img-product"
                        v-if="statement.product.images.length"
                          :src="statement.product.images[0].path"
                          />
                    </b-col>

                   <b-col cols="12" md="5" class="px-md-2 px-0">
                      <span class="subtitle-item"> #{{ statement.product.id }}</span>
                      <p class="title-item">{{ statement.product.name }}</p>
                      <p v-if="statement.product.offer && statement.product.offer.name" class="subtitle-item">{{ statement.product.offer.name + ' - ' + statement.product.offer.hash }}</p>
                    </b-col>

                    <b-col v-if="statement.sale && statement.sale.amount" cols="12" md="3" class="align-items-md-center justify-content-md-center d-md-flex d-block">
                      <span :class="{
                        'entry': statement.type_transaction === 'INCOME',
                        'exit': statement.type_transaction === 'OUT_FLOW',
                      }"
                      class="title-item"
                      >
                        {{ isHeaven ? '$' : 'R$' }} {{ formatMoney(statement.sale.amount) }}
                      </span>
                    </b-col>
                  </div>
               
            
              </b-row>

              <!-- Client -->
              <div
                class="item-dados mt-2 mb-4"
                v-if="statement.sale && statement.sale.client"
              >
               
                  <h5 class="titulo-item" :class="{
                    'heaven': isHeaven,          
                    'entry': statement.type_transaction === 'INCOME',
                    'exit': statement.type_transaction === 'OUT_FLOW',
                      }">
                      {{ $t('seller.contracts.right_preview.text_1798') }}
                    </h5>
              <b-row>
  
                    <b-col cols="12" md="6" class="mt-2">
                      <p class="label-campo">
                        <span class="title-item">
                          {{ $t('seller.contracts.right_preview.text_1796') }}
                        </span>
                        <span  class="subtitle-item">
                          {{ statement.sale.client.name }}
                        </span>
                      </p>
                    </b-col>
  
                    <b-col cols="12" md="6" class="mt-2">
  
                      <p class="label-campo">
                        <span class="title-item">
                          {{ $t('seller.contracts.right_preview.text_1797') }}
                        </span>
                        <span v-if="statement && statement.sale && statement.sale.client" class="subtitle-item">{{ statement.sale.client.email }}
                        </span>
                      </p>
                    </b-col>
  
                    <b-col cols="12" md="6" class="mt-2">
                    <p class="label-campo">
                      <span class="title-item">{{ $t('seller.contracts.right_preview.text_1800') }}</span>
                      <span  v-if="statement && statement.sale && statement.sale.client" class="subtitle-item">{{ statement.sale.client.cellphone }}</span>
                    </p>
                    </b-col>
  
                    <b-col cols="12" md="6" class="mt-2">
                    <p class="label-campo">
                      <span class="title-item" style="text-transform: uppercase;">{{statement.sale.client.document_type ? statement.sale.client.document_type + ': ' : '' }}</span>
                      <span  class="subtitle-item">{{ statement.sale.client.document }}</span>
                    </p>
                    </b-col>
  
              </b-row>
              </div>

              <!-- Antecipation -->          
               <div
                class="item-dados mt-2 mb-4"
                v-if="statement.antecipation_group_id && statement.antecipation_group"
              >
               
                  <h5 class="titulo-item" :class="{
                    'heaven': isHeaven,          
                    'entry': statement.type_transaction === 'INCOME',
                    'exit': statement.type_transaction === 'OUT_FLOW',
                      }">
                      {{ $t('views.seller.statements.antecipation_data') }}
                    </h5>
              <b-row>
  
               
                <b-col cols="12" md="6" >
                  <span class="title-item"> {{ $t('seller.statements.modals.withdraw.text_865') }}</span>
                  <span class="subtitle-item">{{ statement.antecipation_group.id }}</span>
                </b-col>           

                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_257') }}</span>
                  <span class="subtitle-item">{{ statement.antecipation_group.created_at | datetime }}</span>
                </b-col>
                      
                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_258') }}</span>
                  <span class="subtitle-item">{{ $t('seller.contracts.right_preview.text_259') }}</span>
                </b-col>
                
                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_260') }}</span>
                  <span class="subtitle-item">{{ statement.antecipation_group.updated_at | datetime }}</span>
                </b-col>
                
                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_261') }}</span>
                  <span class="subtitle-item">
                    {{ isHeaven ? '$' : 'R$' }} {{ formatMoney(statement.antecipation_group.fee) }}
                  </span>
                </b-col>
                
                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_262') }}</span>
                  <span class="subtitle-item">
                    {{ isHeaven ? '$' : 'R$' }}
                    {{ formatMoney(statement.antecipation_group.amount) }}
                  </span>
                </b-col>
      
              </b-row>              
              </div>
           
              <!-- Withdraw -->          
              <div
                class="item-dados mt-2 mb-4"
                v-if="statement.withdraw_id && statement.withdrawals && statement.withdrawals.account"
              >               
                  <h5 class="titulo-item" :class="{
                    'heaven': isHeaven,          
                    'entry': statement.type_transaction === 'INCOME',
                    'exit': statement.type_transaction === 'OUT_FLOW',
                    }">
                      {{ $t('views.seller.statements.bank_data') }}
                  </h5>
              <b-row>
  
               
                <b-col cols="12" md="6"  class="mt-2">
                  <span class="title-item">
                          {{ $t('seller.contracts.right_preview.text_1796') }}
                        </span>
                  <span class="subtitle-item">
                    {{ statement.withdrawals.account.name }}
                  </span>
                </b-col>

                <b-col cols="12" md="6"  class="mt-2">
                  <span class="title-item">
                          {{ $t('client.text_60') }}
                  </span>
                  <span class="subtitle-item">
                    {{ statement.withdrawals.account.cpf_cnpj }}
                  </span>
                </b-col>

                <b-col cols="12" md="6"  class="mt-2">
                  <span class="title-item">
                    {{ $t('seller.statements.modals.bank_account.text_2714') }}
                  </span>
                  <span class="subtitle-item">
                    {{ statement.withdrawals.account.account }}
                  </span>
                </b-col>
                
                <b-col cols="12" md="6"  class="mt-2">
                  <span class="title-item">
                    {{ $t('seller.statements.modals.bank_account.text_2713') }}  
                  </span>
                  <span class="subtitle-item">
                    {{ statement.withdrawals.account.agency }}
                  </span>
                </b-col>

                <!-- <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_257') }}</span>
                  <span class="subtitle-item">{{ statement.antecipation_group.created_at | datetime }}</span>
                </b-col>
                      
                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_258') }}</span>
                  <span class="subtitle-item">{{ $t('seller.contracts.right_preview.text_259') }}</span>
                </b-col>
                
                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_260') }}</span>
                  <span class="subtitle-item">{{ statement.antecipation_group.updated_at | datetime }}</span>
                </b-col>
                
                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_261') }}</span>
                  <span class="subtitle-item">
                    {{ isHeaven ? '$' : 'R$' }} {{ formatMoney(statement.antecipation_group.fee) }}
                  </span>
                </b-col>
                
                <b-col cols="12" md="6" class="mt-2">
                  <span class="title-item">{{ $t('seller.contracts.right_preview.text_262') }}</span>
                  <span class="subtitle-item">
                    {{ isHeaven ? '$' : 'R$' }}
                    {{ formatMoney(statement.antecipation_group.amount) }}
                  </span>
                </b-col> -->
      
              </b-row>              
              </div>
            </b-overlay>
          </b-col>
          <b-col v-if="has_antecipation">
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <span>{{ $t('seller.contracts.right_preview.text_256') }}</span>
                  <p class="dados-campo amount-available mt-3">
                    {{ isHeaven ? '$' : 'R$' }} {{ formatMoney(antecipation.total) }}
                  </p>
                </b-col>
              </b-row>

              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row>
                    <b-col>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('seller.contracts.right_preview.text_1789') }}</span>
                        <span class="subtitle-item">{{ antecipation.id }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('seller.contracts.right_preview.text_257') }}</span>
                        <span class="subtitle-item">{{ antecipation.created_at | datetime }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('seller.contracts.right_preview.text_258') }}</span>
                        <span class="subtitle-item">{{ $t('seller.contracts.right_preview.text_259') }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('seller.contracts.right_preview.text_260') }}</span>
                        <span class="subtitle-item">{{ antecipation.updated_at | datetime }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('seller.contracts.right_preview.text_261') }}</span>
                        <span class="subtitle-item">
                          {{ isHeaven ? '$' : 'R$' }} {{ formatMoney(antecipation.fee) }}
                        </span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('seller.contracts.right_preview.text_262') }}</span>
                        <span class="subtitle-item">
                          {{ isHeaven ? '$' : 'R$' }}
                          {{ formatMoney(antecipation.amount) }}
                        </span>
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Product -->
              <b-row
                class="item-dados"
                v-if="
                  statement.type == 'SALE' ||
                  statement.type == 'REFUNDED' ||
                  statement.type == 'AMBASSADOR_SALE' ||
                  statement.type == 'AMBASSADOR_REFUNDED' ||
                  statement.type == 'AMBASSADOR_CHARGEBACK'
                "
              >
                <b-col>
                  <h5 class="titulo-item" :class="{'heaven': isHeaven}">{{ $t('seller.contracts.right_preview.text_1794') }}</h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <div class="img-product">
                        <img
                          :src="statement.product.images[0].path"
                          v-if="!loading && statement.product && statement.product.images.length"
                        />
                      </div>
                      <div
                        class="name-product"
                        v-if="!loading && statement.product"
                      >
                        <span>{{ $t('seller.contracts.right_preview.text_1794') }}: #{{ statement.product.id }}</span>
                        <p v-if="statement && statement.product">{{ statement.product.name }}</p>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </b-row>

              <!-- Client -->
              <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item" :class="{'heaven': isHeaven}">{{ $t('seller.contracts.right_preview.text_1798') }}</h5>

                  <p class="label-campo">
                    <span class="title-item">{{ $t('seller.contracts.right_preview.text_1796') }}</span>
                    <span v-if="statement && statement.sale && statement.sale.client">{{ statement.sale.client.name }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">{{ $t('seller.contracts.right_preview.text_1797') }}</span>
                    <span v-if="statement && statement.sale && statement.sale.client">{{ statement.sale.client.email }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">{{ $t('seller.contracts.right_preview.text_1800') }}</span>
                    <span v-if="statement && statement.sale && statement.sale.client">{{ statement.sale.client.cellphone }}</span>
                  </p>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
import Money from '@/mixins/money'

import AccountStatementsService from '@/services/resources/AccountStatementsService'
import AntecipationService from '@/services/resources/AntecipationService'

const service = AccountStatementsService.build()
const serviceAntecipation = AntecipationService.build()

export default {
  name: 'DetalhesExtrato',
  mixins: [Money],
  data() {
    return {
      loading: true,
      antecipation_id: -1,
      statement_id: -1,
      visible: false,
      has_antecipation: false,
      antecipation: null,
      statement: {
        product: {
          name: "",
          description: "",
          images: [{ path: null }]
        },
        sale: {
          client: {
            name: null,
            email: null,
            cellphone: null
          }
        }
      },
      transactionTypes: [
        { type: 'SALE', translation: 'views.seller.statements.sale' },
        { type: 'CHARGEBACK', translation: 'Chargeback' },
        { type: 'REFUNDED', translation: 'views.seller.statements.refund' },
        { type: 'WITHDRAW', translation: 'views.seller.statements.withdraw' },
        { type: 'WITHDRAW_FEE', translation: 'views.seller.statements.withdraw_fee' },
        { type: 'ANTECIPATION_FEE', translation: 'views.seller.statements.antecipation_fee' },
        { type: 'ANTECIPATION_BALANCE', translation: 'views.seller.statements.amount_of_antecipation' },
        { type: 'ANTECIPATION_REMAINING', translation: 'views.seller.statements.antecipation_remaining' },
        { type: 'ANTECIPATION', translation: 'views.seller.statements.antecipation' },
        { type: 'CASHBACK', translation: 'Cashback' },
        { type: 'SHIPPING', translation: 'views.seller.statements.send' },
        { type: 'CURRENCY_CONVERSION_FEE', translation: 'views.seller.statements.conversion_fee' },
        { type: 'AMBASSADOR_SALE', translation: 'views.seller.statements.AMBASSADOR_SALE' },
        { type: 'AMBASSADOR_CHARGEBACK', translation: 'views.seller.statements.AMBASSADOR_CHARGEBACK' },
        { type: 'AMBASSADOR_REFUNDED', translation: 'views.seller.statements.AMBASSADOR_REFUNDED' },
        { type: 'INDICATION_SALE', translation: 'views.seller.statements.INDICATION_SALE' },
        { type: 'INDICATION_CHARGEBACK', translation: 'views.seller.statements.INDICATION_CHARGEBACK' },
        { type: 'INDICATION_REFUNDED', translation: 'views.seller.statements.INDICATION_REFUNDED' },
      ]
    }
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    getType(type) {
      switch (type) {
        case 'SALE': return 'VENDA'
        case 'REFUNDED': return 'REEMBOLSO'
        case 'WITHDRAW': return 'SAQUE'
        case 'WITHDRAW_FEE': return 'TAXA DE SAQUE'
        case 'ANTECIPATION': return 'ANTECIPAÇÃO'
        case 'ANTECIPATION_FEE': return 'TAXA DE ANTECIPAÇÃO'
        case 'ANTECIPATION_BALANCE': return 'SALDO DA ANTECIPAÇÃO'
        case 'ANTECIPATION_REMAINING': return 'ANTECIPAÇÃO RESTANTE'
        default: return ''
      }
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },
    openPanel(id, antecipation) {
      this.visible = true;
      this.has_antecipation = false;
      if (!antecipation) {
        this.statement_id = id;
        this.fetchStatement();
      } else {
        this.antecipation_id = id;
        this.fetchAntecipation();
      }
    },
    fetchAntecipation() {
      this.has_antecipation = true;
      this.antecipation = {};
      this.statement = {
        product: {
          name: "",
          description: "",
          images: [{ path: null }],
        },
      };

      this.loading = true;

      let data = {
        id: this.antecipation_id,
      };

      serviceAntecipation
        .read(data)
        .then((response) => {
          if (response.id) {
            this.antecipation = response;
          } else {
            this.visible = false;
            this.$bvToast.toast(this.$t('seller.statements.right_preview.text_854'), {
              title: this.$t('views.seller.statements.antecipation'),
              variant: "info",
              autoHideDelay: 9000,
              appendToast: true,
            })
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchStatement() {
      this.has_antecipation = false;

      this.loading = true;

      let data = {
        id: this.statement_id,
      };

      service
        .read(data)
        .then((response) => {
          this.statement = response;
          this.statement.type_translate = this.transactionTypes.find(type => type.type === this.statement.type).translation;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.panel {
  .sumary {
    font-size: 13px;
  }

  .product-main {
    display: grid;
    grid-template-columns: 0.5fr 3fr;
    gap: 20px;
    align-items: center;
  }
  .img-product {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-product,
  .img-product img {
    width: 120px;
    height: 80px;
    border-radius: 10px;
    background: #ededf0;
    object-fit: contain;
  }
  .name-product span {
    font-size: 11px;
    color: #81858e;
  }
  .name-product p {
    font-weight: 600;
  }
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  // border: 1px solid ;
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $base-color;
  margin-bottom: 15px;
}
.titulo-item.heaven {
  color: $base-color-heaven !important;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 14px;
  color: #141414;
  display: block;
  font-weight: 600 !important;
  margin-bottom: 3px;
}
.subtitle-item {
  font-size: 15px;
  color: #81858E;
  display: block;
  font-weight: 400 !important;
  margin-bottom: 3px;
  word-wrap: break-word;
}
.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 10px;
}
.amount-available {
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 10px;
}

.entry{
  color: #009488 !important; 
}

.exit{
  color: #FF9E44 !important;
}

.amount-available.mais.heaven {
  color: $base-color-heaven;
}

.badge-transaction {
  position: relative;
  
  &.entry {
    .transactions__status {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 5px;
          padding: 6px;
          background: rgba(0, 148, 136, 0.10);
        }
        .transactions__text {
          color: #009488; 
        }
      }
      
  &.exit {
        .transactions__status {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 5px;
          padding: 6px;
          background: rgba(255, 158, 68, 0.10);
        }
        .transactions__text {
          color: #FF9E44;
        }
    }
}

.header-text {
  font-size: 16px;
  font-weight: 600;
}
.center-item{
  display: flex;
  align-items: center;
  justify-content: center;
}
.voltar-login {
  margin: 40px 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #ededf0;
}
.voltar-login a {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 15px;
}
.voltar-login img {
  transform: rotate(180deg);
  margin-right: 20px;
  margin-top: -2px;
}
</style>
