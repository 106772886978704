import Rest from '@/services/Rest';

/**
 * @typedef {FinancialStatementService}
 */
export default class FinancialStatementService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/v2/financial-statement'

  /**
   * @typedef {Object} FinancialStatementResumeResponse
   * @property {Number} available
   * @property {Number} availableToAntecipate
   * @property {Number} blocked
   * @property {Number} total
   * 
   * @param {?Number} currencyId
   * 
   * @returns {Promise<FinancialStatementResumeResponse>}
   */
  async getResume(currencyId = null) {
    const params = new URLSearchParams();
    if (currencyId)
      params.append('currency_id', currencyId);
    try {
      const { data } = await this.get(`/resume?${params.toString()}`);
      return {
          available: data.available,
          availableToAntecipate: data.available_to_antecipate,
          blocked: data.blocked,
          total: data.total,
      };
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * 
   * 
   * @typedef {Object} FinancialStatementWithdrawResponse
   * @property {Array} data
   * @property {Object} meta
   * 
   * @typedef {Object} FinancialStatementWithdrawParams
   * @property {?Number} currencyId
   * @property {?Number} page 
   * @property {?Number} perPage 
   * 
   * @param {FinancialStatementWithdrawParams} params
   * 
   * @returns {Promise<FinancialStatementWithdrawResponse>}
   */
  async getWithdraws(params = {}) {
    const queryParams = new URLSearchParams();
    if (params.currencyId)
      queryParams.append('currency_id', params.currencyId);
    if (params.page)
      queryParams.append('page', params.page || 1);
    if (params.perPage)
      queryParams.append('per_page', params.perPage || 10);
    try {
      const { data, meta } = await this.get(`/withdraws?${queryParams.toString()}`);
      return {
        data,
        meta
      };
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * 
   * 
   * @typedef {Object} FinancialStatementAntecipationResponse
   * @property {Array} data
   * @property {Object} meta
   * 
   * @typedef {Object} FinancialStatementAntecipationParams
   * @property {?Number} currencyId
   * @property {?Number} page 
   * @property {?Number} perPage 
   * 
   * @param {FinancialStatementAntecipationParams} params
   * 
   * @returns {Promise<FinancialStatementAntecipationResponse>}
   */
  async getAntecipations(params = {}) {
    const queryParams = new URLSearchParams();
    if (params.currencyId)
      queryParams.append('currency_id', params.currencyId);
    if (params.page)
      queryParams.append('page', params.page || 1);
    if (params.perPage)
      queryParams.append('per_page', params.perPage || 10);
    try {
      const { data, meta } = await this.get(`/antecipations?${queryParams.toString()}`);
      return {
        data,
        meta
      };  
    } catch (error) {
      console.error(error)
    }
  }
}