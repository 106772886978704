<template>
  <BaseModal
    name="bank-account-remove"
    size="md"
    title="Remover conta bancária"
  >
    <b-row>
      <b-col>
        <p>Tem certeza que deseja remover esta conta bancária?</p>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel">
        {{ $t('client.text_51') }}
      </BaseButton>
      <BaseButton variant="danger" @click="ok"> Remover conta</BaseButton>
    </template>
  </BaseModal>
</template>
<script>
export default {
  methods: {
    ok() {
      this.$emit("ok");
      this.$bvModal.hide("bank-account-remove");
    },
  },
};
</script>

<style scoped>
p {
  font-size: 15px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
</style>
