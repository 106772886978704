<template>
  <BaseModal
    name="antecipation"
    size="lg"
    :title="$t('seller.statements.modals.antecipation.text_779')"
    @shown="openModal"
    @hidden="reset"
  >
    <!-- Loading -->
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-if="!loading">
      <!-- Avaliable Anticipate Amount -->
      <div class="item-top">
        <img src="@/assets/img/icons/money-anticipation.svg" />
        <div class="money">
          <span>{{ $t('seller.statements.modals.antecipation.text_780') }}</span>
          <p>{{ isHeaven ? '$' : 'R$' }} {{ antecipation ? formatMoney(antecipation) : "0,00" }}</p>
        </div>
      </div>

      <div class="faturas mt-3" v-if="faturas.length">
        <!-- Info -->
        <p>
          {{ $t('seller.statements.modals.antecipation.text_781') }}
        </p>

        <div class="lista-faturas">
          <!-- Want to Anticipate Everything -->
          <b-row>
            <b-col md="6" cols="12">
              <b-form-group
                label-for="antecipar-tudo"
                class="selecionar-tudo"
              >
                <b-form-checkbox
                  name="antecipar-tudo "
                  size="lg"
                  switch
                  v-model="antecipa_tudo"
                  :disabled="loading_update"
                  @change="changeSelectedAll"
                >
                  <p class="info-checkbox">
                    {{ $t('seller.statements.modals.antecipation.text_782') }}
                    <span class="descricao-checkbox"
                      >
                        {{ $t('seller.statements.modals.antecipation.text_783') }}
                      </span
                    >
                  </p>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <!-- Want to Anticipate a Specific Value -->
            <b-col md="5" cols="12">
              <b-form-group
                label="Valor desejado"
                label-for="valor-antecipar"
                id="valor-antecipar"
              >
                <money
                  v-model="valor_desejado"
                  v-bind="money"
                  v-validate="'required'"
                  autocomplete="off"
                  :disabled="antecipa_tudo || loading_update"
                  id="money-valor"
                  class="w-100"
                  :class="{'heaven': isHeaven}"
                >
                </money>
                <p>
                  {{
                    !antecipa_tudo
                      ? $t('seller.statements.modals.antecipation.text_784')
                      : $t('seller.statements.modals.antecipation.text_785')
                  }}
                </p>
              </b-form-group>
            </b-col>
            <!-- Select an Option -->
            <b-col md="7" cols="12">
              <div v-if="opcoes_aproximadas.length"
                data-anima="top"
                class="w-100"
              >
                <b-form-group
                  class="mb-3"
                  :label="$t('seller.statements.modals.antecipation.text_786')"
                >
                  <b-form-invalid-feedback :state="state" class="mb-2"
                    >{{ $t('seller.statements.modals.antecipation.text_2704') }}</b-form-invalid-feedback
                  >
                  <b-form-radio-group
                    v-model="selected_aproximado"
                    :options="opcoes_aproximadas"
                    :state="state"
                    name="radio-validation"
                    @change="changeAproximadas"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <!-- Resume -->
          <div class="item-detalhe mt-4">
            <p class="resumo">{{ $t('seller.statements.modals.antecipation.text_2705') }}</p>
          </div>
          <!-- Requested Anticipation -->
          <div class="item-detalhe">
            <p>{{ $t('seller.statements.modals.antecipation.text_787') }}</p>
            <p class="destaque" :class="{ load_valor: loading_update }">
              {{ isHeaven ? '$' : 'R$' }} {{ solicitado ? formatMoney(solicitado) : "0,00" }}
            </p>
          </div>
          <!-- Anticipation Fee -->
          <div class="item-detalhe">
            <p class="tarifas">{{ $t('seller.statements.modals.antecipation.text_2706') }}: {{ taxa_antecipacao }}%</p>
            <p class="tarifa" :class="{ load_valor: loading_update }">
              -{{ isHeaven ? '$' : 'R$' }} {{ tarifa ? formatMoney(tarifa) : "0,00" }}
            </p>
          </div>
          <!-- Will Be Anticipated -->
          <div class="item-detalhe mb-3">
            <p>{{ $t('seller.statements.modals.antecipation.text_788') }}</p>
            <p class="destaque" :class="{ load_valor: loading_update }">
              {{ isHeaven ? '$' : 'R$' }} {{ antecipacao ? formatMoney(antecipacao) : "0,00" }}
            </p>
          </div>
        </div>
      </div>
      <!-- Feedback Menssage -->
      <div class="faturas mt-3" v-if="!faturas.length">
        <b-row key="nao-encontrado" class="justify-content-center">
          <p class="nao-encontrado">{{ $t('seller.statements.modals.antecipation.text_789') }}</p>
        </b-row>
      </div>
    </div>
    <template v-slot:footer="{ cancel }">
      <!-- Cancel -->
      <BaseButton
        variant="link-info"
        class="mr-4"
        @click="cancel"
        :disabled="loading || loading_update"
      >
        {{ $t('seller.statements.modals.antecipation.text_2707') }}
      </BaseButton>
      <!-- Anticipate Now -->
      <div id="btn-antecipar">
        <BaseButton
          variant="black"
          @click="onSubmit"
          :disabled="
            loading || loading_update || !faturas.length || !solicitado
          "
        >
          {{ $t('seller.statements.modals.antecipation.text_2708') }}
        </BaseButton>
      </div>
      <b-tooltip
        v-if="!faturas.length"
        target="btn-antecipar"
        :title="$t('seller.statements.modals.antecipation.text_789')"
      />
      <b-tooltip
        v-if="!solicitado"
        target="btn-antecipar"
        :title="$t('seller.statements.modals.antecipation.text_791')"
      />
      <b-tooltip
        v-if="loading_update"
        target="btn-antecipar"
        :title="$t('seller.statements.modals.antecipation.text_792')"
      />
    </template>
  </BaseModal>
</template>

<script>
import { Money } from "v-money";
import FormatMoney from "@/mixins/money";
import _ from "lodash";

import SaleService from "@/services/resources/SaleService";
import GlobalSettings from "@/services/resources/GlobalSettings";
import AntecipationService from "@/services/resources/AntecipationService";

const serviceSales = SaleService.build();
const serviceGlobalSettings = GlobalSettings.build();
const serviceAntecipation = AntecipationService.build();

export default {
  name: "Antecipation",
  components: {
    Money,
  },
  mixins: [FormatMoney],
  props: {
    antecipation_status:{},
    antecipation: {
      type: Number,
      default: 0,
    },
    antecipation_fee: {
      type: Number,
      default: 0,
    },
    currency: {
      required: true,
      type: Object,
      default: () => ({
        id: 1,
        currency: "BRL",
        currency_symbol: "R$",
      })
    }
  },
  data() {
    return {
      antecipacao: 0,
      antecipa_tudo: true,
      faturas: [],
      itemFatura: [],
      loading: false,
      loading_update: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      ocultar: false,
      opcoes_antecipacao: null,
      opcoes_aproximadas: [],
      selectedFatura: [],
      selected_aproximado: null,
      solicitado: 0,
      tarifa: 0,
      taxa_antecipacao: 0,
      valor_desejado: 0,
      ver_detalhes: false,
    };
  },
  watch: {
    valor_desejado() {
      this.debounce();
    },
    solicitado() {
      this.antecipacao = this.solicitado - this.tarifa;
    }
  },
  computed: {
    state() {
      return Boolean(this.selected_aproximado);
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    changeAproximadas(event) {
      this.solicitado = event.amount;
      this.tarifa = event.fee;
      this.selectedFatura = event.sales;
    },
    roundValue(num, places) {
      return +parseFloat(num).toFixed(places);
    },
    reset(desejado = false) {
      this.solicitado = 0;
      this.tarifa = 0;
      this.antecipacao = 0;
      this.selected_aproximado = null;
      if (!desejado) {
        this.valor_desejado = 0;
      }
      this.opcoes_aproximadas = [];
    },
    openModal() {
      this.money.prefix = this.currency.currency_symbol+ " "
      this.fetchSales();
      this.antecipa_tudo = true;
      this.taxa_antecipacao = this.antecipation_fee;
      if (!this.taxa_antecipacao) {
        this.fetchGlobalSettings();
        this.solicitado = this.antecipation;
      }
    },
    debounce: _.debounce(function () {
      this.calculaValor();
    }, 500),
    calculaValor() {
      if (
        (!this.valor_desejado &&
          this.roundValue(this.valor_desejado) !=
            this.roundValue(this.solicitado)) ||
        (this.valor_desejado && !this.antecipa_tudo)
      ) {
        this.reset(true);
        this.loading_update = true;

        let data = {
          amount: this.valor_desejado,
          currency_id: this.currency.id
        };
        serviceAntecipation
          .create(data, "/amount")
          .then((response) => {
            // MENOR QUE
            if (response.before.amount && response.before.sales.length) {
              this.opcoes_aproximadas.push({
                text: `${this.$t('seller.statements.modals.antecipation.text_793')} R$ ${this.formatMoney(
                  response.before.amount
                )} (${response.before.sales.length} faturas)`,
                value: response.before,
              });
            }

            // MAIOR QUE
            if (response.after.amount && response.after.sales.length) {
              this.opcoes_aproximadas.push({
                text: `${this.$t('seller.statements.modals.antecipation.text_793')} R$ ${this.formatMoney(
                  response.after.amount
                )} (${response.after.sales.length} faturas)`,
                value: response.after,
              });
            }
          })
          .catch(err => console.error(err))
          .finally(() => { this.loading_update = false });
      }
    },
    debounceUpdateValues: _.debounce(function () {
      this.atualizaValores();
    }, 500),
    atualizaValores() {
      this.loading_update = true;
      let data = {
        sales: [...this.faturas.map((item) => item.id)],
        currency_id: this.currency.id
      };
      serviceAntecipation
        .create(data, "/check")
        .then((response) => {
          this.solicitado = response.amount;
          this.tarifa = response.fee;
          this.valor_desejado = this.solicitado;
        })
        .catch((err) =>  console.error(err))
        .finally(() => { this.loading_update = false });
    },
    changeSelectedAll(event) {
      this.reset();
      this.selectedFatura = [];

      if (event) {
        this.itemFatura.forEach((item) => {
          item.active = true;
          this.selectedFatura.push(item.id);
        });

        this.debounceUpdateValues();
      } else {
        this.antecipa_tudo = false;
        this.itemFatura.forEach((item) => {
          item.active = false;
        });
        this.solicitado = 0;
        this.tarifa = 0;
        this.selectedFatura = [];
        this.valor_desejado = 0;
      }
    },
    onSubmit() {
      this.loading = true;
      let data = {
        sales: this.selectedFatura,
        currency_id: this.currency.id
      };

      serviceAntecipation
        .create(data)
        .then(() => {
          this.$emit("close");
          this.$emit("pending");
          this.$bvModal.hide("antecipation");
          if (this.antecipation_status === 'AUTOMATICALLY_APPROVED'){
            this.$bvToast.toast(
              this.$t('seller.statements.modals.antecipation.text_794'),
              {
                title: this.$t('seller.statements.modals.antecipation.text_2709'),
                variant: "success",
                autoHideDelay: 9000,
                appendToast: true,
              }
            );
          } else {
            this.$bvToast.toast(
              this.$t('seller.statements.modals.antecipation.text_795'),
              {
                title: this.$t('seller.statements.modals.antecipation.text_2709'),
                variant: "success",
                autoHideDelay: 9000,
                appendToast: true,
              }
            );
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false });
    },
    fetchSales() {
      this.loading = true;
      this.itemFatura = [];

      let data = {
        antecipation: true,
        currency_id: this.currency.id
      };
      serviceSales
        .search(data)
        .then((response) => {
          this.faturas = response;

          this.faturas.forEach((item) => {
            this.itemFatura.push({
              active: false,
              id: item.id,
            });
          });

          this.changeSelectedAll(true);
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false });
    },
    fetchGlobalSettings() {
      serviceGlobalSettings
        .search({ keys: "ANTECIPATION_FEE" })
        .then((response) => {
          response.forEach((item) => {
            if (item.key == "ANTECIPATION_FEE") {
              this.taxa_antecipacao = +item.value;
            }
          });
        })
        .catch(err => console.error(err));
    }
  },
  mounted() {
    this.fetchGlobalSettings();
  },
};
</script>

<style scoped lang="scss">
p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
}
.item-top {
  display: flex;
  align-items: center;
  gap: 15px;
}

.item-top + .item-top {
  margin-top: 20px;
}
.item-top span {
  font-size: 12px;
  font-weight: normal;
  color: #81858e;
}
.item-top p {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #333;
}
.info-checkbox {
  margin-left: 5px;
  cursor: pointer;
  max-width: 300px;
  font-weight: 600;
  color: #333;
  margin: 0;
}
.descricao-checkbox {
  font-size: 14px;
}

.top-faturas {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .top-faturas {
    display: block;
  }
}
.header-table-faturas {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}
.item-fatura {
  border-top: 1px #ededf0 solid;
  padding: 10px 0;
  font-size: 14px;
  align-items: center;
}
.item-fatura div {
  color: #81858e;
}
.item-antecipa {
  cursor: pointer;
}
.ver-detalhes {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}
.ver-detalhes.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.item-detalhe {
  /* padding: 0 15px 0 10px; */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-detalhe + .item-detalhe {
  margin-top: 10px;
}
.item-detalhe p.resumo {
  color: #2133d2;
}
.item-detalhe p.destaque {
  color: #333;
}
.item-detalhe p.tarifa {
  color: #ff0c37;
}
.item-detalhe p {
  margin: 0;
  font-size: 15px;
}
.item-detalhe p.load_valor {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  transition: 0.3s;
  color: transparent;
  padding-left: 20px;
}
#money-valor:disabled {
  color: rgba(0, 0, 0, 0.5) !important;
}
.scroll-faturas {
  padding: 0 10px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 768px) {
  .item-detalhe p {
    margin: 0;
    font-size: 14px;
  }
}
.descricao-checkbox {
  max-width: 100%;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>