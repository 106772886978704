<template>
  <BaseModal
    name="statements-export"
    size="lg"
    :title="getTitle()"
    @shown="openModal"
    @hidden="$emit('onHide')"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div v-if="all_transactions === true" class="top-dados">
          <div>
            <p>{{ $t('seller.sales.text_2614_2') }}</p>
          </div>
        </div>
        <b-form novalidate>
          <!-- <b-row v-if="all_transactions === true">
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.sales.text_2615_2')"
                label-for="method"
                class="form-relative"
              >
                <multiselect 
                  v-model="extract_per" 
                  deselect-label="Can't remove this value" 
                  track-by="id" 
                  label="name"
                  :placeholder="$t('seller.sales.text_2617_1')"
                  :options="extract_options" 
                  :searchable="false" 
                  :allow-empty="false"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                >
                  <template v-slot:noResult>
                    <span>{{ $t('seller.sales.text_2620') }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row> -->
          <b-row data-anima="top">
            <b-col v-if="all_transactions === true && extract_per.id === 'TRANSACTIONS'" cols="12">
              <b-form-group
                :label="$t('seller.sales.text_2615_1')"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('dados')"
                  class="btn-todos"
                  >{{ $t('seller.sales.text_2616_1') }}</a
                >
                <multiselect
                  v-model="dados"
                  :placeholder="$t('seller.sales.text_2617_1')"
                  label="name"
                  track-by="id"
                  :options="dice_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                >
                  <template v-slot:noResult>
                    <span>{{ $t('seller.sales.text_2620') }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="fix-zindex" :label="$t('seller.sales.text_2622')">
                <b-form-input
                  v-model="email"
                  :placeholder="$t('seller.sales.text_2623')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('seller.sales.text_2624') }}
      </BaseButton>
      <BaseButton variant="black" @click="onSubmit" :disabled="loading">
        {{ $t('seller.sales.text_2625') }}
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import AccountStatementsService from "@/services/resources/AccountStatementsService";
const serviceAccountStatements = AccountStatementsService.build();
import WithdrawService from "@/services/resources/WithdrawService";
const serviceWithdraw = WithdrawService.build();
import AntecipationService from "@/services/resources/AntecipationService";
const serviceAntecipation = AntecipationService.build();

export default {
  props: ["filters", "all_transactions", "only_withdraw", "only_anticipations", "sendExport"],
  
  components: {
    Multiselect,
  },
  data() {
    return {
      email: "",
      loading: false,
      dados: [],
      advanced_list: [],
      extract_per: 
      { id: "TRANSACTIONS", name: this.$t("views.seller.sales.export_transactions.text_120") },
      extract_options: [
        { id: "TRANSACTIONS", name: this.$t("views.seller.sales.export_transactions.text_120") },
        { id: "SALES_PER_DAY", name: this.$t("views.seller.sales.export_transactions.text_119") },
      ],
      dice_list: [
        { id: "SALE", name: this.$t("views.seller.sales.export_transactions.text_100") },
        { id: "WITHDRAW", name: this.$t("views.seller.sales.export_transactions.text_101") },
        { id: "WITHDRAW_FEE", name: this.$t("views.seller.sales.export_transactions.text_102") },
        { id: "REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_103") },
        { id: "CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_104") },
        { id: "REPROCESSED", name: this.$t("views.seller.sales.export_transactions.text_105")},
        { id: "ANTECIPATION", name: this.$t("views.seller.sales.export_transactions.text_106") },
        { id: "ANTECIPATION_BALANCE", name: this.$t("views.seller.sales.export_transactions.text_107") },
        { id: "ANTECIPATION_FEE", name: this.$t("views.seller.sales.export_transactions.text_108") },
        { id: "ANTECIPATION_REMAINING", name: this.$t("views.seller.sales.export_transactions.text_109") },
        { id: "SHIPPING", name: this.$t("views.seller.sales.export_transactions.text_110") },
        { id: "AMBASSADOR_SALE", name: this.$t("views.seller.sales.export_transactions.text_111") },
        { id: "AMBASSADOR_REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_112") },
        { id: "AMBASSADOR_CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_113") },
        { id: "INDICATION_SALE", name: this.$t("views.seller.sales.export_transactions.text_116") },
        { id: "INDICATION_CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_117") },
        { id: "INDICATION_REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_118") }
      ],
    };
  },
  methods: {
    selecionarTodos(type) {
      switch (type) {
        case "dados": {
          this.dados = [
          { id: "SALE", name: this.$t("views.seller.sales.export_transactions.text_100") },
          { id: "WITHDRAW", name: this.$t("views.seller.sales.export_transactions.text_101") },
          { id: "WITHDRAW_FEE", name: this.$t("views.seller.sales.export_transactions.text_102") },
          { id: "REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_103") },
          { id: "CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_104") },
          { id: "REPROCESSED", name: this.$t("views.seller.sales.export_transactions.text_105")},
          { id: "ANTECIPATION", name: this.$t("views.seller.sales.export_transactions.text_106") },
          { id: "ANTECIPATION_BALANCE", name: this.$t("views.seller.sales.export_transactions.text_107") },
          { id: "ANTECIPATION_FEE", name: this.$t("views.seller.sales.export_transactions.text_108") },
          { id: "ANTECIPATION_REMAINING", name: this.$t("views.seller.sales.export_transactions.text_109") },
          { id: "SHIPPING", name: this.$t("views.seller.sales.export_transactions.text_110") },
          { id: "AMBASSADOR_SALE", name: this.$t("views.seller.sales.export_transactions.text_111") },
          { id: "AMBASSADOR_REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_112") },
          { id: "AMBASSADOR_CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_113") },
          { id: "INDICATION_SALE", name: this.$t("views.seller.sales.export_transactions.text_116") },
          { id: "INDICATION_CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_117") },
          { id: "INDICATION_REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_118") }
          ];
          break;
        }
      }
    },
    onSubmit() {
      this.loading = true;
      let basic = [];

      if(this.only_withdraw == true) {
        let data = {
          id: "/export",
          email: this.email,
        };

        serviceWithdraw
        .createId(data)
        .then(() => {
          this.$emit("show-toast", {
            message: this.$t("seller.sales.text_2626"),
            title: this.$t("seller.sales.text_2613"),
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("statements-export");
        })
        .catch((err) => {
          console.log(err);
          this.$emit("show-toast", {
            message: this.$t("seller.sales.text_2831"),
            title: this.$t("seller.sales.text_2613"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("statements-export");
        })
        .finally(() => {
          this.loading = false;
        });
      } else if(this.only_anticipations == true) {
        let data = {
          id: "/export",
          email: this.email,
        };
        serviceAntecipation
        .createId(data)
        .then(() => {
          this.$emit("show-toast", {
            message: this.$t("seller.sales.text_2626"),
            title: this.$t("seller.sales.text_2613"),
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("statements-export");
        })
        .catch((err) => {
          console.log(err);
          this.$emit("show-toast", {
            message: this.$t("seller.sales.text_2831"),
            title: this.$t("seller.sales.text_2613"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("statements-export");
        })
        .finally(() => {
          this.loading = false;
        });
      } else {
        basic = [];
        let dados = this.dados;
        for (let i = 0; i < dados.length; i++) {
          basic.push(dados[i].id);
        }
        let data = {
          id: "/export",
          type: 'TRANSACTIONS',
          basic: basic,
          email: this.email,
          filters: JSON.stringify(this.filters),
        };
        serviceAccountStatements
        .createId(data)
        .then(() => {
          this.$emit("show-toast", {
            message: this.$t("seller.sales.text_2626"),
            title: this.$t("seller.sales.text_2613"),
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("statements-export");
        })
        .catch((err) => {
          console.log(err);
          this.$emit("show-toast", {
            message: this.$t("seller.sales.text_2831"),
            title: this.$t("seller.sales.text_2613"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("statements-export");
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
    openModal() {
      this.dados = [];
      this.email = this.$store.getters.getProfile.email;
    },
    getTitle() {
      if (this.only_withdraw) {
        return this.$t('views.seller.statements.export_withdraw');
      } else if (this.only_anticipations) {
        return this.$t('views.seller.statements.export_anticipations');
      } else {
        return this.$t('views.seller.statements.export_transactions');
      }
    }
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
