<template>
  <BaseModal
    name="bank-account-bank-list"
    size="md"
    title="Procurar pelo seu Banco"
  >
    <b-row>
      <b-col>
        <b-form-group label="Pesquisar" label-for="search">
          <b-form-input
            id="search"
            name="search"
            v-model="search"
            autocomplete="off"
            placeholder="Código ou nome do banco"
            type="text"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col cols="3">
        <p class="titulo_banco">Código</p>
      </b-col>
      <b-col cols="9">
        <p class="titulo_banco">Banco</p>
      </b-col>
    </b-row>

    <b-row
      v-for="item in banksList"
      :key="item.id"
      @click="select(item)"
      class="BankList-item"
    >
      <b-col cols="3">
        <p class="dados_banco code_banco">
          {{ item.code }}
        </p>
      </b-col>
      <b-col cols="9">
        <p class="dados_banco">
          {{ item.name }}
        </p>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" @click="cancel"> Fechar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import BankService from "@/services/resources/BankService";

const service = BankService.build();

export default {
  data() {
    return {
      search: "",
      banks: [],
    };
  },
  computed: {
    banksList() {
      return this.banks.filter((element) => {
        return (
          element.code.includes(this.search) ||
          element.name
            .toLocaleLowerCase()
            .includes(this.search.toLocaleLowerCase())
        );
      });
    },
  },
  methods: {
    select(item) {
      this.$emit("select", item);
      this.close();
    },
    close() {
      this.$bvModal.hide("bank-account-bank-list");
    },
    fetchBanks() {
      service
        .search()
        .then((response) => {
          this.banks = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.fetchBanks();
  },
};
</script>

<style lang="scss" scoped>
.BankList-item {
  padding: 10px;
  cursor: pointer;
  margin: 5px 0;
  border-radius: 5px;

  &:hover {
    background: #f3f3f3;
  }
}
.titulo_banco {
  font-weight: 600;
  font-size: 15px;
  margin-left: 5px;
}
.dados_banco {
  font-size: 14px;
}
.dados_banco.code_banco {
  font-weight: 600;
}
</style>