import Rest from '@/services/Rest';

/**
 * @typedef {CurrencyConversionService}
 */
export default class CurrencyConversionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/currency/conversion'
}
