import Rest from '@/services/Rest';

/**
 * @typedef {AntecipationService}
 */
export default class AntecipationService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/antecipation'
}